<template>
  <div class="app-container">
    <el-form
      :inline="true"
      :model="queryForm"
      class="demo-form-inline account-top"
    >
      <el-form-item label="搜索">
        <el-input
          v-model="queryForm.search"
          placeholder="关键字"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-plus" @click="addFun">
          提交
        </el-button>
      </el-form-item>
      <!-- <el-form-item>
        <el-button type="primary" @click="$refs.createDialogRef.initFun()">
          生成
        </el-button>
        <createDialog ref="createDialogRef" />
      </el-form-item> -->
    </el-form>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      class="tableList1"
    >
      <el-table-column prop="id" label="ID" width="70" />
      <el-table-column prop="text" label="语料内容"></el-table-column>
      <el-table-column prop="translation" label="译文"></el-table-column>
      <!-- <el-table-column prop="" label="是否公开" width="120" align="center">
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.  == true"
            type="success"
            disable-transitions
          >
            是
          </el-tag>
          <el-tag v-else type="warning" disable-transitions>
            否
          </el-tag>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="target_language_display"
        label="译文语言"
      ></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        width="170"
      ></el-table-column>
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="translateFun(scope.row)"
          >
            翻译
          </el-button>
          <el-button size="mini" type="danger" @click="delFun(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="queryForm.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
    ></el-pagination>
    <addDialog ref="addDialogRef" @updateFun="updateFun" />
    <translateDialog ref="translateDialogRef" @updateFun="updateFun" />
  </div>
</template>

<script>
import { listApi } from '@/api/language'
import addDialog from './components/languageDialog.vue'
import translateDialog from './components/translateDialog.vue'
// import createDialog from './components/languageCreate.vue'
export default {
  components: {
    addDialog,
    translateDialog
    // createDialog
  },
  data() {
    return {
      accountId: 0,
      list: null,
      listLoading: true,
      queryForm: {
        pageIndex: 1,
        pageSize: 10,
        search: ''
      },
      count: 0,

      userList: [],
      current: 1,
      // 每页记录数
      limit: 5,
      // 总记录数
      total: 0
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {},
  methods: {
    async fetchData() {
      this.listLoading = true
      const { all_count: count, items } = await listApi(this.queryForm)
      this.count = count
      this.list = items

      this.listLoading = false
    },
    async handleSearch() {
      this.queryForm.pageIndex = 1
      await this.fetchData()
    },
    handleSizeChange(pageSize) {
      this.queryForm.pageSize = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.queryForm.pageIndex = pageIndex
      // 分页变更时处理
      this.fetchData()
    },

    // 提交账号
    addFun() {
      this.$refs.addDialogRef.initFun()
    },
    //
    updateFun() {
      this.queryForm.pageIndex = 1
      this.fetchData()
    },
    delFun(row) {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          submit_del_api(row.id).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.list = []
            this.queryForm.pageIndex = 1
            this.fetchData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    toPage(row) {
      this.$router.push({
        path: '/twitter/account',
        query: {
          id: row.id
        }
      })
    },
    translateFun(row) {
      this.$refs.translateDialogRef.initFun(row)
    }
  }
}
</script>

<style lang="scss" scoped>
.account-top {
  white-space: nowrap;
}
::v-deep .el-table__body-wrapper {
  height: calc(100vh - 310px) !important;
}
</style>
