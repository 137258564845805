let languageList = [
  {
    id: '206',
    chinese_name: '英语',
    english_name: 'English',
    code: 'en'
  },
  {
    id: '211',
    chinese_name: '中文',
    english_name: 'Chinese',
    code: 'zh'
  },
  {
    id: '47',
    chinese_name: '德语',
    english_name: 'German',
    code: 'de'
  },
  {
    id: '53',
    initial: 'E',
    chinese_name: '俄语',
    english_name: 'Russian',
    code: 'ru'
  },
  {
    id: '55',
    chinese_name: '法语',
    english_name: 'French',
    code: 'fr'
  },
  {
    id: '72',
    chinese_name: '韩语',
    english_name: 'Korean',
    code: 'ko'
  },
  {
    id: '145',
    initial: 'R',
    chinese_name: '日语',
    english_name: 'Japanese',
    code: 'ja'
  },
  {
    id: '1',
    initial: 'A',
    chinese_name: '阿布哈兹语',
    english_name: 'Abkhazian',
    code: 'ab'
  },
  {
    id: '2',
    chinese_name: '阿尔巴尼亚语',
    english_name: 'Albanian',
    code: 'sq'
  },
  {
    id: '3',
    chinese_name: '阿肯语',
    english_name: 'Akan',
    code: 'ak'
  },
  {
    id: '4',
    chinese_name: '阿拉伯语',
    english_name: 'Arabic',
    code: 'ar'
  },
  {
    id: '5',
    chinese_name: '阿拉贡语',
    english_name: 'Aragonese',
    code: 'an'
  },
  {
    id: '6',
    chinese_name: '阿姆哈拉语',
    english_name: 'Amharic',
    code: 'am'
  },
  {
    id: '7',
    chinese_name: '阿萨姆语',
    english_name: 'Assamese',
    code: 'as'
  },
  {
    id: '8',
    chinese_name: '阿塞拜疆语',
    english_name: 'Azerbaijani',
    code: 'az'
  },
  {
    id: '9',
    chinese_name: '阿斯图里亚斯语',
    english_name: 'Asturian',
    code: 'ast'
  },
  {
    id: '10',
    chinese_name: '阿兹特克语',
    english_name: 'Central Huasteca Nahuatl',
    code: 'nch'
  },
  {
    id: '11',
    chinese_name: '埃维语',
    english_name: 'Ewe',
    code: 'ee'
  },
  {
    id: '12',
    chinese_name: '艾马拉语',
    english_name: 'Aymara',
    code: 'ay'
  },
  {
    id: '13',
    chinese_name: '爱尔兰语',
    english_name: 'Irish',
    code: 'ga'
  },
  {
    id: '14',
    chinese_name: '爱沙尼亚语',
    english_name: 'Estonian',
    code: 'et'
  },
  {
    id: '15',
    chinese_name: '奥杰布瓦语',
    english_name: 'Ojibwa',
    code: 'oj'
  },
  {
    id: '16',
    chinese_name: '奥克语',
    english_name: 'Occitan',
    code: 'oc'
  },
  {
    id: '17',
    chinese_name: '奥里亚语',
    english_name: 'Oriya',
    code: 'or'
  },
  {
    id: '18',
    chinese_name: '奥罗莫语',
    english_name: 'Oromo',
    code: 'om'
  },
  {
    id: '19',
    chinese_name: '奥塞梯语',
    english_name: 'Ossetian',
    code: 'os'
  },
  {
    id: '20',
    initial: 'B',
    chinese_name: '巴布亚皮钦语',
    english_name: 'Tok Pisin',
    code: 'tpi'
  },
  {
    id: '21',
    chinese_name: '巴什基尔语',
    english_name: 'Bashkir',
    code: 'ba'
  },
  {
    id: '22',
    chinese_name: '巴斯克语',
    english_name: 'Basque',
    code: 'eu'
  },
  {
    id: '23',
    chinese_name: '白俄罗斯语',
    english_name: 'Belarusian',
    code: 'be'
  },
  {
    id: '24',
    chinese_name: '柏柏尔语',
    english_name: 'Berber languages',
    code: 'ber'
  },
  {
    id: '25',
    chinese_name: '班巴拉语',
    english_name: 'Bambara',
    code: 'bm'
  },
  {
    id: '26',
    chinese_name: '邦阿西楠语',
    english_name: 'Pangasinan',
    code: 'pag'
  },
  {
    id: '27',
    chinese_name: '保加利亚语',
    english_name: 'Bulgarian',
    code: 'bg'
  },
  {
    id: '28',
    chinese_name: '北萨米语',
    english_name: 'Northern Sami',
    code: 'se'
  },
  {
    id: '29',
    chinese_name: '本巴语',
    english_name: 'Bemba (Zambia)',
    code: 'bem'
  },
  {
    id: '30',
    chinese_name: '比林语',
    english_name: 'Blin',
    code: 'byn'
  },
  {
    id: '31',
    chinese_name: '比斯拉马语',
    english_name: 'Bislama',
    code: 'bi'
  },
  {
    id: '32',
    chinese_name: '俾路支语',
    english_name: 'Baluchi',
    code: 'bal'
  },
  {
    id: '34',
    chinese_name: '冰岛语',
    english_name: 'Icelandic',
    code: 'is'
  },
  {
    id: '33',
    chinese_name: '波兰语',
    english_name: 'Polish',
    code: 'pl'
  },
  {
    id: '35',
    chinese_name: '波斯尼亚语',
    english_name: 'Bosnian',
    code: 'bs'
  },
  {
    id: '36',
    chinese_name: '波斯语',
    english_name: 'Persian',
    code: 'fa'
  },
  {
    id: '37',
    chinese_name: '博杰普尔语',
    english_name: 'Bhojpuri',
    code: 'bho'
  },
  {
    id: '38',
    chinese_name: '布列塔尼语',
    english_name: 'Breton',
    code: 'br'
  },
  {
    id: '39',
    initial: 'C',
    chinese_name: '查莫罗语',
    english_name: 'Chamorro',
    code: 'ch'
  },
  {
    id: '40',
    chinese_name: '查瓦卡诺语',
    english_name: 'Chavacano',
    code: 'cbk'
  },
  {
    id: '41',
    chinese_name: '楚瓦什语',
    english_name: 'Chuvash',
    code: 'cv'
  },
  {
    id: '42',
    chinese_name: '聪加语',
    english_name: 'Tsonga',
    code: 'ts'
  },
  {
    id: '43',
    initial: 'D',
    chinese_name: '鞑靼语',
    english_name: 'Tatar',
    code: 'tt'
  },
  {
    id: '44',
    chinese_name: '丹麦语',
    english_name: 'Danish',
    code: 'da'
  },
  {
    id: '45',
    chinese_name: '掸语',
    english_name: 'Shan',
    code: 'shn'
  },
  {
    id: '46',
    chinese_name: '德顿语',
    english_name: 'Tetum',
    code: 'tet'
  },

  {
    id: '48',
    chinese_name: '低地德语',
    english_name: 'Low German',
    code: 'nds'
  },
  {
    id: '49',
    chinese_name: '低地苏格兰语',
    english_name: 'Scots',
    code: 'sco'
  },
  {
    id: '50',
    chinese_name: '迪维西语',
    english_name: 'Dhivehi',
    code: 'dv'
  },
  {
    id: '51',
    chinese_name: '侗语',
    english_name: 'Kam',
    code: 'kdx'
  },
  {
    id: '52',
    chinese_name: '杜順語',
    english_name: 'Kadazan Dusun',
    code: 'dtp'
  },

  {
    id: '54',
    initial: 'F',
    chinese_name: '法罗语',
    english_name: 'Faroese',
    code: 'fo'
  },

  {
    id: '56',
    chinese_name: '梵语',
    english_name: 'Sanskrit',
    code: 'sa'
  },
  {
    id: '57',
    chinese_name: '菲律宾语',
    english_name: 'Filipino',
    code: 'fil'
  },
  {
    id: '58',
    chinese_name: '斐济语',
    english_name: 'Fijian',
    code: 'fj'
  },
  {
    id: '59',
    chinese_name: '芬兰语',
    english_name: 'Finnish',
    code: 'fi'
  },
  {
    id: '60',
    chinese_name: '弗留利语',
    english_name: 'Friulian',
    code: 'fur'
  },
  {
    id: '61',
    chinese_name: '富尔语',
    english_name: 'Fur',
    code: 'fvr'
  },
  {
    id: '62',
    initial: 'G',
    chinese_name: '刚果语',
    english_name: 'Kongo',
    code: 'kg'
  },
  {
    id: '63',
    chinese_name: '高棉语',
    english_name: 'Khmer',
    code: 'km'
  },
  {
    id: '64',
    chinese_name: '格雷罗纳瓦特尔语',
    english_name: 'Guerrero Nahuatl',
    code: 'ngu'
  },
  {
    id: '65',
    chinese_name: '格陵兰语',
    english_name: 'Kalaallisut',
    code: 'kl'
  },
  {
    id: '66',
    chinese_name: '格鲁吉亚语',
    english_name: 'Georgian',
    code: 'ka'
  },
  {
    id: '67',
    chinese_name: '格罗宁根方言',
    english_name: 'Gronings',
    code: 'gos'
  },
  {
    id: '68',
    chinese_name: '古吉拉特语',
    english_name: 'Gujarati',
    code: 'gu'
  },
  {
    id: '69',
    chinese_name: '瓜拉尼语',
    english_name: 'Guarani',
    code: 'gn'
  },
  {
    id: '70',
    initial: 'H',
    chinese_name: '哈萨克语',
    english_name: 'Kazakh',
    code: 'kk'
  },
  {
    id: '71',
    chinese_name: '海地克里奥尔语',
    english_name: 'Haitian',
    code: 'ht'
  },

  {
    id: '73',
    chinese_name: '豪萨语',
    english_name: 'Hausa',
    code: 'ha'
  },
  {
    id: '74',
    chinese_name: '荷兰语',
    english_name: 'Dutch',
    code: 'nl'
  },
  {
    id: '75',
    chinese_name: '黑山语',
    english_name: 'Montenegrin',
    code: 'cnr'
  },
  {
    id: '76',
    chinese_name: '胡帕语',
    english_name: 'Hupa',
    code: 'hup'
  },
  {
    id: '77',
    initial: 'J',
    chinese_name: '基里巴斯语',
    english_name: 'Gilbertese',
    code: 'gil'
  },
  {
    id: '78',
    chinese_name: '基隆迪语',
    english_name: 'Rundi',
    code: 'rn'
  },
  {
    id: '79',
    chinese_name: '基切语',
    english_name: "K'iche'",
    code: 'quc'
  },
  {
    id: '80',
    chinese_name: '吉尔吉斯斯坦语',
    english_name: 'Kirghiz',
    code: 'ky'
  },
  {
    id: '81',
    chinese_name: '加利西亚语',
    english_name: 'Galician',
    code: 'gl'
  },
  {
    id: '82',
    chinese_name: '加泰罗尼亚语',
    english_name: 'Catalan',
    code: 'ca'
  },
  {
    id: '83',
    chinese_name: '捷克语',
    english_name: 'Czech',
    code: 'cs'
  },
  {
    id: '84',
    initial: 'K',
    chinese_name: '卡拜尔语',
    english_name: 'Kabyle',
    code: 'kab'
  },
  {
    id: '85',
    chinese_name: '卡纳达语',
    english_name: 'Kannada',
    code: 'kn'
  },
  {
    id: '86',
    chinese_name: '卡努里语',
    english_name: 'Kanuri',
    code: 'kr'
  },
  {
    id: '87',
    chinese_name: '卡舒比语',
    english_name: 'Kashubian',
    code: 'csb'
  },
  {
    id: '88',
    chinese_name: '卡西语',
    english_name: 'Khasi',
    code: 'kha'
  },
  {
    id: '89',
    chinese_name: '康沃尔语',
    english_name: 'Cornish',
    code: 'kw'
  },
  {
    id: '90',
    chinese_name: '科萨语',
    english_name: 'Xhosa',
    code: 'xh'
  },
  {
    id: '91',
    chinese_name: '科西嘉语',
    english_name: 'Corsican',
    code: 'co'
  },
  {
    id: '92',
    chinese_name: '克里克语',
    english_name: 'Creek',
    code: 'mus'
  },
  {
    id: '93',
    chinese_name: '克里米亚鞑靼语',
    english_name: 'Crimean Tatar',
    code: 'crh'
  },
  {
    id: '94',
    chinese_name: '克林贡语',
    english_name: 'Klingon',
    code: 'tlh'
  },
  {
    id: '95',
    chinese_name: '克罗地亚语',
    english_name: 'Serbo-Croatian',
    code: 'hbs'
  },
  {
    id: '96',
    chinese_name: '克丘亚语',
    english_name: 'Quechua',
    code: 'qu'
  },
  {
    id: '97',
    chinese_name: '克什米尔语',
    english_name: 'Kashmiri',
    code: 'ks'
  },
  {
    id: '98',
    chinese_name: '库尔德语',
    english_name: 'Kurdish',
    code: 'ku'
  },
  {
    id: '99',
    initial: 'L',
    chinese_name: '拉丁语',
    english_name: 'Latin',
    code: 'la'
  },
  {
    id: '100',
    chinese_name: '拉特加莱语',
    english_name: 'Latgalian',
    code: 'ltg'
  },
  {
    id: '101',
    chinese_name: '拉脱维亚语',
    english_name: 'Latvian',
    code: 'lv'
  },
  {
    id: '102',
    chinese_name: '老挝语',
    english_name: 'Lao',
    code: 'lo'
  },
  {
    id: '103',
    chinese_name: '立陶宛语',
    english_name: 'Lithuanian',
    code: 'lt'
  },
  {
    id: '104',
    chinese_name: '林堡语',
    english_name: 'Limburgish',
    code: 'li'
  },
  {
    id: '105',
    chinese_name: '林加拉语',
    english_name: 'Lingala',
    code: 'ln'
  },
  {
    id: '106',
    chinese_name: '卢干达语',
    english_name: 'Ganda',
    code: 'lg'
  },
  {
    id: '107',
    chinese_name: '卢森堡语',
    english_name: 'Letzeburgesch',
    code: 'lb'
  },
  {
    id: '108',
    chinese_name: '卢森尼亚语',
    english_name: 'Rusyn',
    code: 'rue'
  },
  {
    id: '109',
    chinese_name: '卢旺达语',
    english_name: 'Kinyarwanda',
    code: 'rw'
  },
  {
    id: '110',
    chinese_name: '罗马尼亚语',
    english_name: 'Romanian',
    code: 'ro'
  },
  {
    id: '111',
    chinese_name: '罗曼什语',
    english_name: 'Romansh',
    code: 'rm'
  },
  {
    id: '112',
    chinese_name: '罗姆语',
    english_name: 'Romany',
    code: 'rom'
  },
  {
    id: '113',
    chinese_name: '逻辑语',
    english_name: 'Lojban',
    code: 'jbo'
  },
  {
    id: '114',
    initial: 'M',
    chinese_name: '马达加斯加语',
    english_name: 'Malagasy',
    code: 'mg'
  },
  {
    id: '115',
    chinese_name: '马恩语',
    english_name: 'Manx',
    code: 'gv'
  },
  {
    id: '116',
    chinese_name: '马耳他语',
    english_name: 'Maltese',
    code: 'mt'
  },
  {
    id: '117',
    chinese_name: '马拉地语',
    english_name: 'Marathi',
    code: 'mr'
  },
  {
    id: '118',
    chinese_name: '马拉雅拉姆语',
    english_name: 'Malayalam',
    code: 'ml'
  },
  {
    id: '119',
    chinese_name: '马来语',
    english_name: 'Malay',
    code: 'ms'
  },
  {
    id: '120',
    chinese_name: '马里语（俄罗斯）',
    english_name: 'Mari (Russia)',
    code: 'chm'
  },
  {
    id: '121',
    chinese_name: '马其顿语',
    english_name: 'Macedonian',
    code: 'mk'
  },
  {
    id: '122',
    chinese_name: '马绍尔语',
    english_name: 'Marshallese',
    code: 'mh'
  },
  {
    id: '123',
    chinese_name: '玛雅语',
    english_name: 'Kekchí',
    code: 'kek'
  },
  {
    id: '124',
    chinese_name: '迈蒂利语',
    english_name: 'Maithili',
    code: 'mai'
  },
  {
    id: '125',
    chinese_name: '毛里求斯克里奥尔语',
    english_name: 'Morisyen',
    code: 'mfe'
  },
  {
    id: '126',
    chinese_name: '毛利语',
    english_name: 'Maori',
    code: 'mi'
  },
  {
    id: '127',
    chinese_name: '蒙古语',
    english_name: 'Mongolian',
    code: 'mn'
  },
  {
    id: '128',
    chinese_name: '孟加拉语',
    english_name: 'Bengali',
    code: 'bn'
  },
  {
    id: '129',
    chinese_name: '缅甸语',
    english_name: 'Burmese',
    code: 'my'
  },
  {
    id: '130',
    chinese_name: '苗语',
    english_name: 'Hmong',
    code: 'hmn'
  },
  {
    id: '131',
    chinese_name: '姆班杜语',
    english_name: 'Umbundu',
    code: 'umb'
  },
  {
    id: '132',
    initial: 'N',
    chinese_name: '纳瓦霍语',
    english_name: 'Navajo',
    code: 'nv'
  },
  {
    id: '133',
    chinese_name: '南非语',
    english_name: 'Afrikaans',
    code: 'af'
  },
  {
    id: '134',
    chinese_name: '尼泊尔语',
    english_name: 'Nepali',
    code: 'ne'
  },
  {
    id: '135',
    chinese_name: '纽埃语',
    english_name: 'Niuean',
    code: 'niu'
  },
  {
    id: '136',
    chinese_name: '挪威语',
    english_name: 'Norwegian',
    code: 'no'
  },
  {
    id: '137',
    initial: 'P',
    chinese_name: '帕姆语',
    english_name: 'Pam',
    code: 'pmn'
  },
  {
    id: '138',
    chinese_name: '帕皮阿门托语',
    english_name: 'Papiamento',
    code: 'pap'
  },
  {
    id: '139',
    chinese_name: '旁遮普语',
    english_name: 'Panjabi',
    code: 'pa'
  },
  {
    id: '140',
    chinese_name: '葡萄牙语',
    english_name: 'Portuguese',
    code: 'pt'
  },
  {
    id: '141',
    chinese_name: '普什图语',
    english_name: 'Pushto',
    code: 'ps'
  },
  {
    id: '142',
    initial: 'Q',
    chinese_name: '齐切瓦语',
    english_name: 'Nyanja',
    code: 'ny'
  },
  {
    id: '143',
    chinese_name: '契维语',
    english_name: 'Twi',
    code: 'tw'
  },
  {
    id: '144',
    chinese_name: '切罗基语',
    english_name: 'Cherokee',
    code: 'chr'
  },

  {
    id: '146',
    chinese_name: '瑞典语',
    english_name: 'Swedish',
    code: 'sv'
  },
  {
    id: '147',
    initial: 'S',
    chinese_name: '萨摩亚语',
    english_name: 'Samoan',
    code: 'sm'
  },
  {
    id: '148',
    chinese_name: '桑戈语',
    english_name: 'Sango',
    code: 'sg'
  },
  {
    id: '149',
    chinese_name: '僧伽罗语',
    english_name: 'Sinhala',
    code: 'si'
  },
  {
    id: '150',
    chinese_name: '上索布语',
    english_name: 'Upper Sorbian',
    code: 'hsb'
  },
  {
    id: '151',
    chinese_name: '世界语',
    english_name: 'Esperanto',
    code: 'eo'
  },
  {
    id: '152',
    chinese_name: '斯洛文尼亚语',
    english_name: 'Slovenian',
    code: 'sl'
  },
  {
    id: '153',
    chinese_name: '斯瓦希里语',
    english_name: 'Swahili',
    code: 'sw'
  },
  {
    id: '154',
    chinese_name: '索马里语',
    english_name: 'Somali',
    code: 'so'
  },
  {
    id: '155',
    chinese_name: '斯洛伐克语',
    english_name: 'Slovak',
    code: 'sk'
  },
  {
    id: '156',
    initial: 'T',
    chinese_name: '他加禄语',
    english_name: 'Tagalog',
    code: 'tl'
  },
  {
    id: '157',
    chinese_name: '塔吉克语',
    english_name: 'Tajik',
    code: 'tg'
  },
  {
    id: '158',
    chinese_name: '塔希提语',
    english_name: 'Tahitian',
    code: 'ty'
  },
  {
    id: '159',
    chinese_name: '泰卢固语',
    english_name: 'Telugu',
    code: 'te'
  },
  {
    id: '160',
    chinese_name: '泰米尔语',
    english_name: 'Tamil',
    code: 'ta'
  },
  {
    id: '161',
    chinese_name: '泰语',
    english_name: 'Thai',
    code: 'th'
  },
  {
    id: '162',
    chinese_name: '汤加语（汤加群岛）',
    english_name: 'Tonga (Tonga Islands)',
    code: 'to'
  },
  {
    id: '163',
    chinese_name: '汤加语（赞比亚）',
    english_name: 'Tonga (Zambia)',
    code: 'toi'
  },
  {
    id: '164',
    chinese_name: '提格雷尼亚语',
    english_name: 'Tigrinya',
    code: 'ti'
  },
  {
    id: '165',
    chinese_name: '图瓦卢语',
    english_name: 'Tuvalu',
    code: 'tvl'
  },
  {
    id: '166',
    chinese_name: '图瓦语',
    english_name: 'Tuvinian',
    code: 'tyv'
  },
  {
    id: '167',
    chinese_name: '土耳其语',
    english_name: 'Turkish',
    code: 'tr'
  },
  {
    id: '168',
    chinese_name: '土库曼语',
    english_name: 'Turkmen',
    code: 'tk'
  },
  {
    id: '169',
    initial: 'W',
    chinese_name: '瓦隆语',
    english_name: 'Walloon',
    code: 'wa'
  },
  {
    id: '170',
    chinese_name: '瓦瑞语（菲律宾）',
    english_name: 'Waray (Philippines)',
    code: 'war'
  },
  {
    id: '171',
    chinese_name: '威尔士语',
    english_name: 'Welsh',
    code: 'cy'
  },
  {
    id: '172',
    chinese_name: '文达语',
    english_name: 'Venda',
    code: 've'
  },
  {
    id: '173',
    chinese_name: '沃拉普克语',
    english_name: 'Volapük',
    code: 'vo'
  },
  {
    id: '174',
    chinese_name: '沃洛夫语',
    english_name: 'Wolof',
    code: 'wo'
  },
  {
    id: '175',
    chinese_name: '乌德穆尔特语',
    english_name: 'Udmurt',
    code: 'udm'
  },
  {
    id: '176',
    chinese_name: '乌尔都语',
    english_name: 'Urdu',
    code: 'ur'
  },
  {
    id: '177',
    chinese_name: '乌孜别克语',
    english_name: 'Uzbek',
    code: 'uz'
  },
  {
    id: '178',
    initial: 'X',
    chinese_name: '西班牙语',
    english_name: 'Spanish',
    code: 'es'
  },
  {
    id: '179',
    chinese_name: '西方国际语',
    english_name: 'Interlingue',
    code: 'ie'
  },
  {
    id: '180',
    chinese_name: '西弗里斯兰语',
    english_name: 'Western Frisian',
    code: 'fy'
  },
  {
    id: '181',
    chinese_name: '西里西亚语',
    english_name: 'Silesian',
    code: 'szl'
  },
  {
    id: '182',
    chinese_name: '希伯来语',
    english_name: 'Hebrew',
    code: 'he'
  },
  {
    id: '183',
    chinese_name: '希利盖农语',
    english_name: 'Hiligaynon',
    code: 'hil'
  },
  {
    id: '184',
    chinese_name: '夏威夷语',
    english_name: 'Hawaiian',
    code: 'haw'
  },
  {
    id: '185',
    chinese_name: '现代希腊语',
    english_name: 'Modern Greek',
    code: 'el'
  },
  {
    id: '186',
    chinese_name: '新共同语言',
    english_name: 'Lingua Franca Nova',
    code: 'lfn'
  },
  {
    id: '187',
    chinese_name: '信德语',
    english_name: 'Sindhi',
    code: 'sd'
  },
  {
    id: '188',
    chinese_name: '匈牙利语',
    english_name: 'Hungarian',
    code: 'hu'
  },
  {
    id: '189',
    chinese_name: '修纳语',
    english_name: 'Shona',
    code: 'sn'
  },
  {
    id: '190',
    chinese_name: '宿务语',
    english_name: 'Cebuano',
    code: 'ceb'
  },
  {
    id: '191',
    chinese_name: '叙利亚语',
    english_name: 'Syriac',
    code: 'syr'
  },
  {
    id: '192',
    chinese_name: '巽他语',
    english_name: 'Sundanese',
    code: 'su'
  },
  {
    id: '193',
    initial: 'Y',
    chinese_name: '亚美尼亚语',
    english_name: 'Armenian',
    code: 'hy'
  },
  {
    id: '194',
    chinese_name: '亚齐语',
    english_name: 'Achinese',
    code: 'ace'
  },
  {
    id: '195',
    chinese_name: '伊班语',
    english_name: 'Iban',
    code: 'iba'
  },
  {
    id: '196',
    chinese_name: '伊博语',
    english_name: 'Igbo',
    code: 'ig'
  },
  {
    id: '197',
    chinese_name: '伊多语',
    english_name: 'Ido',
    code: 'io'
  },
  {
    id: '198',
    chinese_name: '伊洛卡诺语',
    english_name: 'Iloko',
    code: 'ilo'
  },
  {
    id: '199',
    chinese_name: '伊努克提图特语',
    english_name: 'Inuktitut',
    code: 'iu'
  },
  {
    id: '200',
    chinese_name: '意大利语',
    english_name: 'Italian',
    code: 'it'
  },
  {
    id: '201',
    chinese_name: '意第绪语',
    english_name: 'Yiddish',
    code: 'yi'
  },
  {
    id: '202',
    chinese_name: '因特语',
    english_name: 'Interlingua',
    code: 'ia'
  },
  {
    id: '203',
    chinese_name: '印地语',
    english_name: 'Hindi',
    code: 'hi'
  },
  {
    id: '204',
    chinese_name: '印度尼西亚语',
    english_name: 'Indonesia',
    code: 'id'
  },
  {
    id: '205',
    chinese_name: '印古什语',
    english_name: 'Ingush',
    code: 'inh'
  },

  {
    id: '207',
    chinese_name: '约鲁巴语',
    english_name: 'Yoruba',
    code: 'yo'
  },
  {
    id: '208',
    chinese_name: '越南语',
    english_name: 'Vietnamese',
    code: 'vi'
  },
  {
    id: '209',
    initial: 'Z',
    chinese_name: '扎扎其语',
    english_name: 'Zaza',
    code: 'zza'
  },
  {
    id: '210',
    chinese_name: '爪哇语',
    english_name: 'Javanese',
    code: 'jv'
  },

  {
    id: '212',
    chinese_name: '中文繁体',
    english_name: 'Traditional Chinese',
    code: 'zh-tw'
  },
  {
    id: '213',
    chinese_name: '中文粤语',
    english_name: 'Cantonese',
    code: 'yue'
  },
  {
    id: '214',
    chinese_name: '祖鲁语',
    english_name: 'Zulu',
    code: 'zu'
  }
]
export { languageList }
