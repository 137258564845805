<template>
  <el-dialog
    title="翻译"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <div>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="语言" prop="target_language">
          <el-select
            style="width: 100%;"
            filterable
            clearable
            v-model="form.target_language"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.chinese_name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitFun">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { translateApi } from '@/api/language'
import { languageList } from '@/assets/js/language'
export default {
  data() {
    return {
      dialogVisible: false,
      form: {},
      rules: {
        target_language: [
          {
            required: true,
            message: '请选择语言',
            trigger: 'change'
          }
        ]
      },
      options: languageList
    }
  },
  methods: {
    initFun(row) {
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }

      this.form = {
        id: row.id
      }
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    submitFun() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          translateApi(this.form.id, this.form).then(res => {
            // this.$message.success('提交成功！')
            this.dialogVisible = false
            this.$emit('updateFun')
          })
        }
      })
    }
  }
}
</script>
